<template>
<div id="page-etiquetas" class="non-printable">
  <div>
    <v-breadcrumbs :items="breadcrumbs">
      <v-icon slot="divider">forward</v-icon>
    </v-breadcrumbs>
  </div>
  <v-container fluid fill-height class="non-printable">
    <v-row align-center justify-center>
      <v-col>
        <base-material-card color="primary" icon="library_books" title="Solicitud de Etiquetas" class="elevation-1 px-5 py-3">
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn fab dark x-small color="blue" @click.native="openModalCodigo(1)" title="Solicitud por Código" v-tippy>
              <v-icon dark>mdi-barcode</v-icon>
            </v-btn>&nbsp;
            <v-btn fab dark x-small color="secondary" @click.native="openModal(1)" title="Crear Solicitud" v-tippy>
              <v-icon dark>add</v-icon>
            </v-btn>
            
          </v-card-actions>

          <v-card-text>
            <div id="datagrid" v-on:keyup.enter="registros.items = []; $refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');">

              <v-client-table ref="vuetable"
                :data="registros.items" :columns="columns" :options="options" class="elevation-2 pa-2">
                    <template slot="noResults">
                        <v-alert :value="true" icon="info" color="info">No se encontró ningún registro</v-alert>
                    </template>
                    <template slot="fecha" slot-scope="props">
                        {{ parse_date_time(props.row.fecha) }}
                    </template>
                    <template slot="articulos" slot-scope="props">
                        <v-chip color="blue" label outlined x-small @click="verArticulos(props.row)">
                            Ver {{ props.row.articulos.length }} artículos
                        </v-chip>
                    </template>
                    <template slot="actions" slot-scope="props">
                        <v-speed-dial direction="left" open-on-hover>
                            <template v-slot:activator>
                            <v-btn x-small fab dark color="primary">
                                <v-icon v-if="fab[props.row._id]">mdi-close</v-icon>
                                <v-icon v-else>reorder</v-icon>
                            </v-btn>
                            </template>
                            <v-btn x-small fab dark color="blue darken-2"
                                v-if="permiso_impresion"
                                @click.native="cambiarEstatus(props.row)" title="Editar Estatus" v-tippy>
                                <v-icon>mdi-more</v-icon>
                            </v-btn>
                            <v-btn x-small fab dark color="red"
                                v-if="['Pendiente', 'Cancelada'].includes(props.row.estatus)"
                                @click.native="eliminarOrden(props.row)" title="Eliminar" v-tippy>
                                <v-icon>delete</v-icon>
                            </v-btn>
                            <v-btn x-small fab dark color="purple darken-1"
                                @click.native="editarObservaciones(props.row)" title="Observaciones" v-tippy>
                                <v-icon>mdi-note-plus</v-icon>
                            </v-btn>
                            <v-btn x-small fab dark color="success"
                                v-if="permiso_impresion"
                                @click.native="showModalPlantilla(props.row)" title="Generar Plantilla" v-tippy>
                                <v-icon>mdi-file-word</v-icon>
                            </v-btn>

                        </v-speed-dial>

                    </template>

                </v-client-table>
                <PaginacionComponent ref="pagination_pw" :props="props_paginacion"></PaginacionComponent>
            </div>
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>


  <!-- MODAL ARTICULOS -->
  <v-dialog v-model="modalArticulos" max-width="85%">
    <v-card>
      <v-card-title>
        <v-card class="primary white--text titulomodal">
          <v-icon class="white--text">{{ update ? "edit" : "add" }}</v-icon>&nbsp;
          <span class="subheading">
            <strong>Artículos en Solicitud de Impresión de Etiquetas</strong>
          </span>
        </v-card>
        <v-spacer></v-spacer>
        <v-btn icon @click.native="modalArticulos = false" class="close_modal">
          <v-icon class="white--text">cancel</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
          <v-container grid-list-md>
              <v-row v-show="(['Pendiente'].includes(model.estatus) || (model.estatus==null || model.estatus==''))" >
                <v-col sm="4" md="4" lg="4">
                    <v-autocomplete
                        v-model="id_articulo" :items="articulos" :hide-no-data="true"
                        :loading="isLoading" ref="selectarticulo"
                        item-text="nombre" item-value="_id"
                        label="Buscar por nombre de Artículo"
                        hint="Ingrese nombre del artículo"
                        >
                    </v-autocomplete>
                </v-col>
                <v-col sm="5" md="5" lg="5">
                    <v-autocomplete
                        v-model="id_articulo" :items="articulos" :hide-no-data="true"
                        :loading="isLoading"
                        :item-text="get_nombre_articulo" item-value="_id"
                        label="Buscar por Código de Barras"
                        hint="Ingrese código de barras"
                        >
                    </v-autocomplete>
                </v-col>
                <v-col sm="3" md="3" lg="3">
                    <v-btn small dark color="success" v-show="id_articulo!=null && id_articulo!=''"
                            @click.native="ingresarCantidad(id_articulo)"
                            title="Agregar a Solicitud" v-tippy>
                        <v-icon>mdi-arrow-down-bold-circle</v-icon>Agregar
                    </v-btn>
                </v-col>
            </v-row>

            <v-row>
                <v-col lg="12" md="12" sm="12" v-show="model.observaciones!=null && model.observaciones!=''">
                    <b>Observaciones:</b><br>
                    {{model.observaciones}}
                </v-col>
            </v-row><br>
            <v-row>
                <v-col>
                    <v-client-table ref="vuetablearticulosorden" :data="model.articulos" :columns="columnsArticulosVista" :options="optionsArticulosVista" class="elevation-2 pa-2">
                        <template slot="codigo_articulo" slot-scope="props">
                            {{ props.row.codigo_articulo[0] }}
                        </template>
                        <template slot="precio" slot-scope="props">
                            <div style="text-align:right;">${{ props.row.precio.toFixed(2) }}</div>
                        </template>
                        <template slot="actions" slot-scope="props" >
                            <v-speed-dial direction="left" open-on-hover>
                            <template v-slot:activator>
                            <v-btn x-small fab dark color="primary" v-show="['Pendiente'].includes(model.estatus)">
                                <v-icon v-if="fab[props.row.id_articulo]">mdi-close</v-icon>
                                <v-icon v-else>reorder</v-icon>
                            </v-btn>
                            </template>
                                <v-btn x-small fab dark color="pink" @click.native="deleteArticulo(props.row.id_articulo, props.row.tipo_precio)"
                                    v-show="['Pendiente'].includes(model.estatus)"
                                    title="Descartar Artículo" v-tippy>
                                    <v-icon>delete</v-icon>
                                </v-btn>
                                &nbsp;
                                <v-btn x-small fab dark color="teal darken-1" @click.native="editArticulo(props.row)"
                                    v-show="['Pendiente'].includes(model.estatus)"
                                    title="Editar Cantidad" v-tippy>
                                    <v-icon>edit</v-icon>
                                </v-btn> &nbsp;


                            </v-speed-dial>
                        </template>
                    </v-client-table>
                </v-col>
            </v-row>
          </v-container>

      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click.native="modalArticulos=false">
          <v-icon>cancel</v-icon> Cerrar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <!-- MODAL CODIGO DIRECTO -->
  <v-dialog v-model="modalCodigo" max-width="35%">
    <v-card>
      <v-card-title>
        <v-card class="primary white--text titulomodal">
          <v-icon class="white--text">{{ update ? "edit" : "add" }}</v-icon>&nbsp;
          <span class="subheading">
            <strong>Impresión de Etiquetas por Código de Barras</strong>
          </span>
        </v-card>
        <v-spacer></v-spacer>
        <v-btn icon @click.native="modalCodigo = false" class="close_modal">
          <v-icon class="white--text">cancel</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
          <v-container grid-list-md>
              <v-row v-show="(['Pendiente'].includes(model.estatus) || (model.estatus==null || model.estatus==''))" >
                <v-col sm="12" md="12" lg="12">
                    <v-text-field label="Ingrese Código de Artículo" v-model="codigo_articulo"
                        @keyup.enter="codigo_articulo = codigo_articulo.toString().toUpperCase(); crearSolicitudCodigo()"
                        ref="selectarticulo">
                    </v-text-field>                    
                </v-col>               
            </v-row>            
          </v-container>

      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click.native="modalCodigo=false">
          <v-icon>cancel</v-icon> Cerrar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <!-- MODAL EDITAR ARTICULO -->
  <v-dialog v-model="modalEditable" max-width="60%">
    <v-card>
      <v-card-title>
        <v-card class="primary white--text titulomodal">
          <v-icon class="white--text">{{ update ? "edit" : "add" }}</v-icon>&nbsp;
          <span class="subheading">
            <strong>Editar Artículo</strong>
          </span>
        </v-card>
        <v-spacer></v-spacer>
        <v-btn icon class="close_modal">
          <v-icon class="white--text"></v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form ref="formeditable" lazy-validation>
          <v-container grid-list-md>
              <v-row>
                <v-col sm="8" md="8" lg="8">
                    <v-text-field label="Articulo" v-model="articulo_editable.nombre_articulo" readonly ></v-text-field>
                </v-col>
                <v-col sm="4" md="4" lg="4">
                    <v-text-field label="Cantidad Etiquetas" v-model="articulo_editable.cantidad" :rules="[rules.required, validaCantidadEnteraRequerido]"></v-text-field>
                </v-col>
            </v-row>


          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click.native="modalEditable=false">
          <v-icon>cancel</v-icon> Cancelar
        </v-btn>
        <v-btn color="success" @click.native="saveNuevaCantidadArticulo()">
          <v-icon>done</v-icon> Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <!-- MODAL OBSERVACION -->
  <v-dialog v-model="modalObservacion" max-width="50%">
    <v-card>
      <v-card-title>
        <v-card class="primary white--text titulomodal">
          <v-icon class="white--text">edit</v-icon>&nbsp;
          <span class="subheading">
            <strong>Editar Observaciones</strong>
          </span>
        </v-card>
        <v-spacer></v-spacer>
        <v-btn icon class="close_modal">
          <v-icon class="white--text"></v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form ref="formobservacion" lazy-validation>
          <v-container grid-list-md>
            <v-row>
              <v-col sm="12" md="12" lg="12">
                <v-textarea outlined label="Observaciones" rows="4" v-model="model.observaciones" ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click.native="modalObservacion=false">
          <v-icon>cancel</v-icon> Cerrar
        </v-btn>
        &nbsp;&nbsp;
        <v-btn color="success" @click.native="guardarObservaciones()">
          <v-icon>done</v-icon> Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog v-model="modalCantidad" max-width="50%">
    <v-card>
      <v-card-title>
        <v-card class="primary white--text titulomodal">
          <v-icon class="white--text">edit</v-icon>&nbsp;
          <span class="subheading">
            <strong>Cantidad de Etiquetas</strong>
          </span>
        </v-card>
        <v-spacer></v-spacer>
        <v-btn icon @click.native="modalCantidad = false" class="close_modal">
          <v-icon class="white--text">cancel</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form ref="formcantidad" lazy-validation >
          <v-container>
            <v-row justify="center">
              <v-col sm="4" md="4" lg="4" style="text-align:center !important;">
                <v-text-field label="Cantidad" v-model="cantidad_editable" ref="ingresacantidad"
                    v-on:keydown.enter.prevent='1' @keyup.enter="agregarArticulo()"
                  :rules="[validaCantidadEnteraRequerido]"></v-text-field>
              </v-col>
              <v-col sm="4" md="4" lg="4" style="text-align:center !important;">
                <v-autocomplete v-model="tipo_precio_editable"
                    :items="row_selected.precios_venta" :hide-no-data="true"
                    @change="setPrecio()"
                    item-text="tipo_precio" item-value="tipo_precio"
                    label="Seleccione Precio" :rules="[rules.required]">
                </v-autocomplete>
              </v-col>
              <v-col sm="4" md="4" lg="4" style="text-align:center !important;">
                <v-text-field label="Precio" disabled v-model="precio_editable"
                    v-on:keydown.enter.prevent='1' @keyup.enter="agregarArticulo()"
                  :rules="[validaCantidadFloatRequerido]"></v-text-field>
              </v-col>
            </v-row>
            <v-row justify="center">
              &nbsp;
            </v-row>
            <v-row justify="center">
              <v-btn  @click.native="modalCantidad = false">
                <v-icon>cancel</v-icon> Cancelar
              </v-btn>
              &nbsp;&nbsp;
              <v-btn  color="success" @click.native="agregarArticulo()">
                <v-icon>done</v-icon> Aceptar
              </v-btn>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

    </v-card>
  </v-dialog>

  <v-dialog v-model="modalEstatus" max-width="500px">
    <v-card>
      <v-card-title>
        <v-card class="primary white--text titulomodal">
          <v-icon class="white--text">edit</v-icon>&nbsp;
          <span class="subheading">
            <strong>Editar Estatus</strong>
          </span>
        </v-card>
        <v-spacer></v-spacer>
        <v-btn icon @click.native="modalEstatus = false" class="close_modal">
          <v-icon class="white--text">cancel</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form ref="formcantidad" lazy-validation >
          <v-container>
            <v-row justify="center">
              <v-col sm="10" md="10" lg="10" style="text-align:center !important;">
                <v-autocomplete v-model="estatus_editable" :items="options.listColumns.estatus" :hide-no-data="true"
                    item-text="id" item-value="id" label="Seleccione Estatus" :rules="[rules.required]">
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row justify="center">
              &nbsp;
            </v-row>
            <v-row justify="center">
              <v-btn small @click.native="modalEstatus = false">
                <v-icon>cancel</v-icon> Cancelar
              </v-btn>
              &nbsp;&nbsp;
              <v-btn small color="success" @click.native="changeEstatus()">
                <v-icon>done</v-icon> Aceptar
              </v-btn>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

    </v-card>
  </v-dialog>

  <v-dialog v-model="modalPlantilla" max-width="40%">
    <v-card>
      <v-card-title>
        <v-card class="primary white--text titulomodal">
          <v-icon class="white--text">mdi-file-word</v-icon>&nbsp;
          <span class="subheading">
            <strong>Generar Plantilla de Word</strong>
          </span>
        </v-card>
        <v-spacer></v-spacer>
        <v-btn icon @click.native="modalPlantilla = false" class="close_modal">
          <v-icon class="white--text">cancel</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form ref="formplantilla" lazy-validation>
          <v-container>
            <v-row>
              <v-col>
                  <v-autocomplete v-model="tipo_plantilla" :items="tipos_plantilla"
                    :hide-no-data="true" :hide-selected="true" item-text="nombre" item-value="_id"
                    label="Seleccione Tipo de Plantilla" :rules="[rules.required]">
                  </v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click.native="modalPlantilla=false">
          <v-icon>cancel</v-icon> Cerrar
        </v-btn>
        <v-btn color="success" @click.native="generaPlantilla">
          <v-icon>done</v-icon> Generar Plantilla
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

</div>
</template>

<script>
import PaginacionComponent from '@/components/PaginacionComponent.vue';
export default {
  components: {
    "PaginacionComponent": PaginacionComponent,
  },
  watch: {
    model_filters: {
      handler(val) {
        // this.$nextTick(() => {
        //   this.registros.items = [];
        //   this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
        // });
      },
      deep: true
    },
    search(val) {
        //Si no ha escrito 2 letras o mas
        if (val == null || val.length<2 ) return
        // Si todavía esta buscando
        if (this.isLoading) return
        this.isLoading = true
        let data = {
                    "selector": {
                        "type":"articulos",
                        "nombre": {"$regex": "(?i)" + val.toString().replace("(","\\(").replace(")","\\)").replace("+","\\+")},
                        "estatus": "Activo"
                    },                    
                };

            window.axios
                .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/',data)
                .then(response => {
                    if (response.data.docs.length > 0){
                        response.data.docs.sort(this.ordenar_nombre);
                        this.articulos = response.data.docs;
                    }else
                        this.articulos = [];
                })
                .catch(error => {
                    this.$swal({
                        type: "error",
                        title: "¡Operación no Permitida!",
                        text: "Ocurrió un error al obtener los articulos.",
                        footer: ""
                    });
                }).then(()=>{ this.isLoading=false; });
      },
  },
  mounted: function() {
    this.model_filters = this.$refs.vuetable.$refs.table.query;
    if (!(this.verificaPermiso('t.precios.etiquetas') || this.verificaPermiso('t.precios.etiquetas.impresion'))) {
      this.$router.replace("AccessDenied").catch(() => {});
    }
    //Para detectar el cambio de estatus por lsita y que busque, sin darle enter
    const select = document.getElementsByName('vf__estatus')[0];
    select.addEventListener('change', this.onStatusChanged);
  },
  created: function() {
        this.usuario = this.$local_storage.get("sb_usuario");
        if(this.verificaPermiso("t.precios.etiquetas.impresion")){
            this.permiso_impresion = true;
        }
        this.getArticulos();
        this.get_plantillas();
  },
  data() {
    return {
        codigo_articulo:"",
        permiso_impresion:false,
        precio_editable:"",
        tipo_precio_editable:"",
        mayoreo_editable:0,
        tipo_plantilla: "",
        tipos_plantilla:[],
        estatus_editable: "",

        isLoading:false,
        sb_sucursal:"",
        nombre_sucursal:"",
        usuario:"",
        model_vista: {
            articulos:"",
            proveedor:""
        },
        model_filters: "",
        props_paginacion: {
            infiniteHandler: this.infiniteHandler,
            total_registros: 0
        },
        fab: [],
        modalCorreo:false,

        modalPlantilla:false,
        modalEstatus:false,
        modalVistaPrevia:false,
        modalObservacion:false,
        modalBusqueda:false,
        modalEditable:false,
        modalEditar:false,
        modalArticulos:false,
        modalCodigo:false,
        modalCantidad:false,
        cantidad_editable:"",
        articulo_editable: {},
        barcode_orden:"",
        row_selected:"",
        articulos_vista:[],
        menu1:false,
        menu2:false,
        name: "datagrid",
        columns: [
            "fecha","usuario","articulos","estatus", "actions"
        ],
        options: {
            filterable: ["fecha","usuario","articulos","estatus",],
            headings: {
                "articulos":"Artículos",
                "actions":"Operaciones"
            },
            listColumns: {
                estatus: [
                    {id: "Pendiente",text: "Pendiente"},
                    {id: "Impresa",text: "Impresa"},
                    {id: "Cancelada",text: "Cancelada"},
                ],
            }

        },
        breadcrumbs: [{
            text: "Inicio",
            disabled: false,
            to: "/index"
            },
            {
                text: "Precios",
                href: "",
                disabled: true,
            },
            {
                text: "Solicitud de Etiquetas",
                href: ""
            }
        ],

        update: false,
        modelEditable:{},
        correo:{
            correos:"",
            responder_a:"",
            asunto:"",
            mensaje:""
        },
        model:{
            _id:"",
            _rev:"",
            type: "etiquetas",
            fecha:"",
            estatus: "", //Pendiente, Impresa, Cancelada
            articulos:[],
            usuario:"",
            observaciones:"",
        },
        actual:"",
        cantidad:"",
        actualEditable:"",
        cantidadEditable:"",
        id_articulo:"",
        id_proveedor:"",
        nombre_categoria:"",

        articulo:{
            id_articulo:"",
            codigo_articulo:"",
            nombre_articulo:"",
            descripcion: "",
            cantidad:"",
            precio:"",
        },
        columnsArticulos: [ "articulo.codigo_barras","articulo.nombre","proveedor.nombre","proveedor.plazo","actions"],
        optionsArticulos: {
            filterable: ["articulo.codigo_barras","articulo.nombre","proveedor.nombre","proveedor.plazo"],
            headings: {
                "articulo.codigo_barras":"Código",
                "articulo.nombre":"Artículo",
                "proveedor.nombre":"Proveedor",
                "proveedor.plazo":"Plazo Proveedor",
                "actions":"Opciones",
            },
        },

        columnsArticulosVista: [ "codigo_articulo","nombre_articulo", "descripcion","cantidad","tipo_precio","precio","actions"],
        optionsArticulosVista: {
            filterable: ["codigo_articulo","nombre_articulo","cantidad","descripcion","tipo_precio","precio"],
            headings: {
                "codigo_articulo":"Código",
                "nombre_articulo":"Artículo",
                "descripcion":"Descripción",
                "cantidad":"Cantidad de Etiquetas",
                "actions":"Opciones"},
        },

        rules: {
            required: v => !!v || "Este campo es requerido",
            entero: function(numero) {
                const pattern = /^[\d]*$/;
                return pattern.test(numero) || "Debe ser un número entero";
            },
        },
        search: "",
        searchProveedor: "",
        searchCategoria: "",
        registros: {
            selected: [],
            items: []
        },
        sucursales:[],
        all_sucursales:[],
        articulos:[],
        proveedores:[],
        categorias:[],
        articulos_encontrados:[],
    };
  },
  methods: {
      onStatusChanged(event) {
        this.registros.items = []; 
        this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
      },
      infiniteHandler: function($state) {
      var rows_per_page = 10;
      var page = Math.ceil(this.registros.items.length / rows_per_page); // == 1
      var skip = page * rows_per_page;
      var where = {"type":"etiquetas"};

      this.options.filterable.forEach(x => {
        if (this.model_filters[x]) {
            if (x == "articulos") {
                where[x] = {
                  "$elemMatch": {
                    "nombre_articulo": {"$regex": "(?i)" + this.model_filters[x]}
                  }
                }
            } else {
              where[x] = {
                "$regex": "(?i)" + this.model_filters[x].toString().replace("(","\\(").replace(")","\\)").replace("+","\\+")
              }
            }
          }
      });

      window.axios({
          method: 'POST',
          url: process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/',
          withCredentials: true,
          data: {
            limit: rows_per_page,
            skip: skip,
            selector: where,
            sort: [{"fecha": "desc"}],
            use_index:"_design/9875c00ac5acb2f0b9ec0c519462f98528945724"
          },
        }).then(response => {
          if (response.data.docs.length) {
            var unique_records = new Set(this.registros.items.map(item => item._id));
            unique_records = Array.from(unique_records.values());            
            var nuevos = response.data.docs.filter(e => !unique_records.includes(e._id));
            this.registros.items = this.registros.items.concat(nuevos);            
            this.props_paginacion.total_registros = this.registros.items.length;
            $state.loaded();
          } else {
            $state.complete();
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    getArticulos:function(){
        this.isLoading = true;
        let data = {
            "selector": {
                "type": "articulos",
                "estatus":"Activo",
                //"proveedores.0": { "$exists": true }
            },
            "fields":["_id","nombre","descripcion","codigo_barras","precios_venta"],
            "use_index":"_design/268be5c91c6ac7ca4ba302055e92efd1ee038633"
        };
        window.axios
            .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
            .then(response => {
                response.data.docs.sort(this.ordenar_nombre);
                this.articulos = response.data.docs;
            })
            .catch(error => {
                this.$swal({
                    type: "error",
                    title: "¡Operación no Permitida!",
                    text: "Ocurrió un error al obtener los articulos.",
                    footer: ""
                });
            }).then(() => {
                this.isLoading = false;
            });
    },

    get_nombre_articulo:function(row){      
      return row.codigo_barras.join(", ")+" - "+row.nombre;
    },

    articuloEnOrden: function(id_articulo, id_proveedor){
        var seleccionado = 0;
        for(var k in this.registros.items){
            var mapArt = this.registros.items[k].articulos.map( a =>{return a.id_articulo;});
            if(this.registros.items[k].estatus == "Orden Abierta" && this.registros.items[k].proveedor.id_proveedor==id_proveedor && mapArt.includes(id_articulo))
                seleccionado = 1;
        }
        if(seleccionado == 1)
            return true;
        else
            return false;
    },


    parse_date(date) {
      if (!date) return null;
      return window.moment(String(date)).format("YYYY-MM-DD");
    },
    parse_date_time(date) {
      if (!date) return null;
      return window.moment(String(date)).format("YYYY-MM-DD HH:mm");
    },
    parse_date_time_mx(date) {
      if (!date) return null;
      return window.moment(String(date)).format("DD-MM-YYYY HH:mm");
    },

    getNombreArticulo: function(id_articulo){
        var filter = this.articulos.find(e => e._id==id_articulo);
        if(filter)
            return filter.nombre;
        else
            return ""
    },

    getArticulo: function(id_articulo){
        var filter = this.articulos.find(e => e._id==id_articulo);
        if(filter)
            return filter;
        else
            return ""
    },

    getCodigoArticulo: function(id_articulo){
        var filter = this.articulos.find(e => e._id==id_articulo);
        if(filter)
            return filter.codigo_barras;
        else
            return ""
    },

    validaActual: function(val){
        if(this.actual != null && this.actual !="" ){
            if(parseFloat(val) > parseFloat(this.actual))
                return "La cantidad es mayor a la actual";
            if(parseFloat(val) <= 0 )
                return "La cantidad debe ser mayor que 0";
        }
        return true;
    },
    validaActualEditable: function(val){
        if(this.actualEditable != null && this.actualEditable !="" ){
            if(parseFloat(val) > parseFloat(this.actualEditable))
                return "La cantidad es mayor a la actual";
            if(parseFloat(val) <= 0 )
                return "La cantidad debe ser mayor que 0";
        }
        return true;
    },

    verArticulos: function(row){
        window.axios
        .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/" + row._id)
        .then(response => {          
            this.model = response.data;
            this.modalArticulos=true;            
        })
        .catch(error => {
          this.$swal({
            type: "error",
            title: "¡Operación no Permitida!",
            text: "Ocurrió un error al obtener el registro",
            footer: ""
          });
        });        
    },

    validaCantidadFloatRequerido: function(value) {
      const pattern = /^[\d]*(\.{0,1}[\d]*)$/;
      if (value != null && value != "" && !pattern.test(value)) {
        return "Cantidad no válida"
      } else if (value != null && value != ""){
        if(parseFloat(value)<=0)
          return "Debe ser mayor que cero";
      } else if(value != null && value == "")
            return "Campo requerido";
      return true;
    },

    validaCantidadEnteraRequerido: function(value) {
      const pattern = /^[\d]*$/;
      if (value != null && value != "" && !pattern.test(value)) {
        return "Cantidad no válida"
      } else if (value != null && value != ""){
        if(parseInt(value)<=0)
          return "Debe ser mayor que cero";
      } else if(value != null && value == "")
            return "Campo requerido";
      return true;
    },

    validaCantidadFloat: function(value) {
      const pattern = /^[\d]*(\.{0,1}[\d]*)$/;
      if (value != null && value != "" && !pattern.test(value)) {
        return "Cantidad no válida"
      } else if (value != null && value != ""){
        if(parseFloat(value)<=0)
          return "Debe ser mayor que cero";
      }
      return true;
    },

    openModal: function(type) {
      if (type === 1) {
        this.update = false;
        this.model = {
            _id:"",
            _rev:"",
            type: "etiquetas",
            fecha:"",
            estatus: "", //Pendiente, Impresa, Cancelada
            articulos:[],
            usuario:"",
            observaciones:"",
        }

      } else {
        this.update = true;
      }
      //this.modalBusqueda = true;
      this.modalArticulos = true;
      setTimeout(() => {
            this.$nextTick(() => this.$refs.selectarticulo.focus());
        }, 100);
    },

    openModalCodigo: function(type) {
      if (type === 1) {
        this.update = false;
        this.model = {
            _id:"",
            _rev:"",
            type: "etiquetas",
            fecha:"",
            estatus: "", //Pendiente, Impresa, Cancelada
            articulos:[],
            usuario:"",
            observaciones:"",
        }

      } else {
        this.update = true;
      }      
      this.codigo_articulo = "";
      this.modalCodigo = true;
      setTimeout(() => {
            this.$nextTick(() => this.$refs.selectarticulo.focus());
        }, 100);
    },


    editArticulo: function(articulo){
        this.articulo_editable = articulo;
        this.modalEditable = true;
    },


    saveNuevaCantidadArticulo: function(){
        if (this.$refs.formeditable.validate()) {

            try {

                var descartado = this.model.articulos.filter( e => {
                    return e.id_articulo == this.articulo_editable.id_articulo;
                });
                if(descartado.length>0){
                    window.dialogLoader.show('Espere un momento por favor..');

                    //Editar articulo del modelo con los cambios
                    for(var key in this.model.articulos){
                        if(this.model.articulos[key].id_articulo == this.articulo_editable.id_articulo){
                            this.model.articulos[key].cantidad = parseInt(this.articulo_editable.cantidad);
                        }
                    }

                    window.axios
                        .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + this.model._id + '?conflicts=true', this.model)
                        .then(response => {
                            this.model._rev = response.data.rev;
                            this.registros.items = [];
                            this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
                        })
                        .catch(error => {
                            this.$swal({
                                    type: "error",
                                    title: "¡Operación no Permitida!",
                                    text: "Ocurrió un error al editar el artículo. Intente nuevamente.",
                                    footer: ""
                                });
                        }).then(() => {
                            this.modalEditable = false;
                            window.dialogLoader.hide();
                        });

                }

            } catch (error) {
                this.$swal({
                    type: "error",
                    title: "¡Operación no Permitida!",
                    text: "Ocurrió un error al editar el registro. Intente nuevamente.",
                    footer: ""
                });
                console.log(error);
                window.dialogLoader.hide();
            }

        }
    },

    setPrecio: function(){
      var self= this;
      //console.log(this.row_selected);
      //console.log(self.tipo_precio_editable);
      if(this.tipo_precio_editable!=null && this.tipo_precio_editable!=""){
        var filter = this.row_selected.precios_venta.find(function(e){
          return e.tipo_precio == self.tipo_precio_editable;
        });
        if(filter){
          self.precio_editable = filter.precio_con_impuestos;
          self.mayoreo_editable = filter.de;
        } else {
          self.precio_editable = "Sin Precio";
          self.mayoreo_editable = 0;
        }
      }
    },

    ingresarCantidad: function(id_articulo){
        this.row_selected = this.getArticulo(id_articulo);
        this.cantidad_editable = "";
        this.precio_editable = "";
        this.tipo_precio_editable="";
        this.modalCantidad = true;
        setTimeout(() => {
            this.$nextTick(() => this.$refs.ingresacantidad.focus());
        }, 100);
    },

    agregarArticulo: function(){
        try {
            if (this.$refs.formcantidad.validate()) {
              var self = this;
                window.dialogLoader.show('Espere un momento por favor..');

                var articulo = this.row_selected;
                var cantidad = parseInt(this.cantidad_editable);
                var precio = parseFloat(this.precio_editable);
                var tipo_precio = this.tipo_precio_editable;
                var mayoreo_de = this.mayoreo_editable;

                if(self.model._id != ""){//esta editando
                  var articuloExiste = 0;
                  for(var key in self.model.articulos){
                      if(self.model.articulos[key].id_articulo == articulo._id && self.model.articulos[key].tipo_precio == tipo_precio){
                          articuloExiste = 1;
                          self.model.articulos[key].cantidad += cantidad;
                          self.model.articulos[key].precio = precio;
                          self.model.articulos[key].mayoreo = mayoreo_de;
                      }
                  }

                  if(articuloExiste == 0){ //Si no lo encontró, se agrega

                      var model = {
                          id_articulo: articulo._id,
                          codigo_articulo: articulo.codigo_barras,
                          nombre_articulo: articulo.nombre,
                          descripcion: articulo.descripcion,
                          cantidad: cantidad,
                          tipo_precio: tipo_precio,
                          mayoreo: mayoreo_de,
                          precio: precio,
                      };
                      self.model.articulos.push(model);                      
                  } 
                  window.axios
                    .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/', self.model)
                    .then(response => {
                        self.model._id = response.data.id;
                        self.model._rev = response.data.rev;
                        window.dialogLoader.showSnackbar('Artículo agregado correctamente', {
                            color: 'success'
                        });
                        self.registros.items = [];
                        self.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
                    })
                    .catch(error => {
                        self.$swal({
                            type: "error",
                            title: "¡Operación no Permitida!",
                            text: "Ocurrió un error al agregar artículo a la solicitud. Intente nuevamente.",
                            footer: error
                        });
                    }).then(() => {
                        window.dialogLoader.hide();
                        this.modalCantidad = false;
                        this.id_articulo = "";
                        this.$nextTick(() => this.$refs.selectarticulo.focus());
                    });
                } else {//es una nueva solicitud de etiqueta
                  var model = {
                      type:"etiquetas",
                      fecha: window.moment().format("YYYY-MM-DDTHH:mm:ss"),
                      estatus: "Pendiente",
                      articulos:[
                          {
                              id_articulo: articulo._id,
                              nombre_articulo: articulo.nombre,
                              descripcion: articulo.descripcion,
                              codigo_articulo: articulo.codigo_barras,
                              cantidad: cantidad,
                              precio: precio,
                              tipo_precio: tipo_precio,
                              mayoreo: mayoreo_de,
                          }
                      ],
                      usuario: self.usuario,
                      observaciones:"",
                  };


                  window.axios
                      .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/', model)
                      .then(response => {
                          self.model = model;
                          self.model._id = response.data.id;
                          self.model._rev = response.data.rev;
                          //console.log("Response", response);
                          window.dialogLoader.showSnackbar('Artículo agregado correctamente', {
                              color: 'success'
                          });
                          self.registros.items = [];
                          self.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
                      })
                      .catch(error => {
                          this.$swal({
                              type: "error",
                              title: "¡Operación no Permitida!",
                              text: "Ocurrió un error al agregar artículo a la solicitud. Intente nuevamente.",
                              footer: error
                          });
                      }).then(() => {
                          window.dialogLoader.hide();
                          this.modalCantidad = false;
                          this.id_articulo = "";
                          this.$nextTick(() => this.$refs.selectarticulo.focus());
                      });
                }
            }

        } catch (error) {
            window.dialogLoader.hide();
            this.$swal({
                type: "error",
                title: "¡Operación no Permitida!",
                text: "Ocurrió un error al crear la solicitud. Intente nuevamente.",
                footer: ""
            });
            console.log(error);
        }

    },

    deleteArticulo: function(idArticulo, tipoPrecio){

        var descartado = this.model.articulos.filter( e => {
            return e.id_articulo == idArticulo;
        });
        if(descartado.length>0){
            window.dialogLoader.show('Espere un momento por favor..');

            //Eliminar articulo del modelo
            var filter = this.model.articulos.filter( e => {
                return e.id_articulo != idArticulo || e.tipo_precio != tipoPrecio;
            });
            this.model.articulos = filter;
            if(this.model.articulos.length == 0){//Si ya no tiene articulos se elimina
                window.axios
                    .delete(process.env.VUE_APP_COUCHDB_URL+ '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/" + this.model._id + "?rev=" + this.model._rev)
                    .then(response => {

                    })
                    .catch(error => {
                        this.$swal({
                            type: "error",
                            title: "¡Operación no Permitida!",
                            text: "Ocurrió un error al eliminar el artículo. Intente nuevamente.",
                            footer: error
                        });
                    }).then(() => {
                        this.modalArticulos = false;
                        this.registros.items = [];
                        this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
                        window.dialogLoader.hide();
                    });
            } else {//Si no se elimina se guarda
                window.axios
                    .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + this.model._id + '?conflicts=true', this.model)
                    .then(response => {
                        this.model._rev = response.data.rev;
                    })
                    .catch(error => {
                        this.$swal({
                                type: "error",
                                title: "¡Operación no Permitida!",
                                text: "Ocurrió un error al eliminar el artículo. Intente nuevamente.",
                                footer: error
                            });
                    }).then(() => {
                        this.registros.items = [];
                        this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
                        window.dialogLoader.hide();
                    });
            }

        }
    },

    crearSolicitudCodigo: function(){
        try {
            if (this.codigo_articulo != undefined && this.codigo_articulo != null && this.codigo_articulo!="") {

              var self = this;
                window.dialogLoader.show('Espere un momento por favor..');

                var articulo_find = this.articulos.find(a => a.codigo_barras.includes(self.codigo_articulo));

                if(articulo_find){
                    var articulo = articulo_find;
                    this.row_selected = articulo_find;
                    this.tipo_precio_editable = "Público General"
                    var cantidad = 1;  // 1 por default
                    this.setPrecio(); //obtiene el precio y el mayoreo_de
                    var precio = parseFloat(this.precio_editable);
                    var tipo_precio = this.tipo_precio_editable;
                    var mayoreo_de = this.mayoreo_editable;

                    //Si la ultima solicitud sigue Pendiente de impresión y es del mismo usuario, se agrega ahi el articulo, sino se crea nueva solicitud
                    var model = "";
                    if(this.registros.items[0].estatus == "Pendiente" && this.registros.items[0].usuario == self.usuario){
                      model = this.registros.items[0];
                      model.articulos.push({
                        id_articulo: articulo._id,
                        nombre_articulo: articulo.nombre,
                        descripcion: articulo.descripcion,
                        codigo_articulo: articulo.codigo_barras,
                        cantidad: cantidad,
                        precio: precio,
                        tipo_precio: tipo_precio,
                        mayoreo: mayoreo_de,
                      });

                    } else {
                      model = {
                            type:"etiquetas",
                            fecha: window.moment().format("YYYY-MM-DDTHH:mm:ss"),
                            estatus: "Pendiente",
                            articulos:[
                                {
                                    id_articulo: articulo._id,
                                    nombre_articulo: articulo.nombre,
                                    descripcion: articulo.descripcion,
                                    codigo_articulo: articulo.codigo_barras,
                                    cantidad: cantidad,
                                    precio: precio,
                                    tipo_precio: tipo_precio,
                                    mayoreo: mayoreo_de,
                                }
                            ],
                            usuario: self.usuario,
                            observaciones:"",
                        };                      
                      }

                      if(model != ""){
                        window.axios
                            .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/', model)
                            .then(response => {
                                self.model = model;
                                self.model._id = response.data.id;
                                self.model._rev = response.data.rev;
                                //console.log("Response", response);
                                window.dialogLoader.showSnackbar('Artículo agregado correctamente', {
                                    color: 'success'
                                });
                                self.registros.items = [];
                                self.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
                            })
                            .catch(error => {
                                this.$swal({
                                    type: "error",
                                    title: "¡Operación no Permitida!",
                                    text: "Ocurrió un error al agregar artículo a la solicitud. Intente nuevamente.",
                                    footer: error
                                });
                            }).then(() => {
                                window.dialogLoader.hide();                              
                                this.codigo_articulo = "";
                                this.$nextTick(() => this.$refs.selectarticulo.focus());
                            });
                    }
 
                } else {
                  window.dialogLoader.hide();
                  window.dialogLoader.showSnackbar('Artículo NO encontrado', {
                      color: 'error'
                  });
                  this.codigo_articulo = "";
                  this.$nextTick(() => this.$refs.selectarticulo.focus());
                }                               
            }

        } catch (error) {
            window.dialogLoader.hide();
            this.$swal({
                type: "error",
                title: "¡Operación no Permitida!",
                text: "Ocurrió un error al crear la solicitud. Intente nuevamente.",
                footer: ""
            });
            console.log(error);
        }

    },


    editarObservaciones: function(row){
        this.model = row;
        this.modalObservacion = true;
    },

    guardarObservaciones: function(){
        if (this.$refs.formobservacion.validate()) {
            window.dialogLoader.show('Espere un momento por favor..');

            window.axios
                .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + this.model._id + '?conflicts=true', this.model)
                .then(response => {
                    window.dialogLoader.showSnackbar('Las observaciones se guardaron correctamente', {
                        color: 'success'
                    });
                })
                .catch(error => {
                    this.$swal({
                            type: "error",
                            title: "¡Operación no Permitida!",
                            text: "Ocurrió un error al guardar observaciones. Favor de intentar nuevamente.",
                            footer: error
                        });
                }).then(() => {
                    this.modalObservacion = false;
                    this.registros.items = [];
                    this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
                    window.dialogLoader.hide();
                });
        }
    },

    eliminarOrden: function(row){
        this.$swal({
            type: "info",
            title: "Cuidado",
            text: "¿Realmente desea eliminar la solicitud?",
            footer: "",
            showCancelButton: true,
            cancelButtonColor: "#d33",
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Si",
            cancelButtonText: "No"
        }).then(resultado => {
            if (resultado.value) {

                window.dialogLoader.show('Espere un momento por favor..');


                window.axios
                    .delete(process.env.VUE_APP_COUCHDB_URL+ '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/" + row._id + "?rev=" + row._rev)
                    .then(response => {
                        window.dialogLoader.showSnackbar('La solicitud se eliminó correctamente..', {
                            color: 'success'
                        });
                    })
                    .catch(error => {
                        this.$swal({
                            type: "error",
                            title: "¡Operación no Permitida!",
                            text: "Ocurrió un error al eliminar la solicitud. Intente nuevamente.",
                            footer: error
                        });
                    }).then(() => {
                        this.modalBusqueda = false;
                        this.registros.items = [];
                        this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
                        window.dialogLoader.hide();
                    });

            }
        });
    },
    cambiarEstatus(row){
        this.model_vista = row;
        this.estatus_editable = row.estatus;
        this.modalEstatus = true;
    },
    changeEstatus(){
        try {
            window.dialogLoader.show('Espere un momento por favor..');
            this.model_vista.estatus = this.estatus_editable;
            window.axios
                .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + this.model_vista._id + '?conflicts=true', this.model_vista)
                .then(response => {
                    this.model_vista._rev = response.data.rev;
                })
                .catch(error => {
                    console.log(error);
                    this.$swal({
                            type: "error",
                            title: "¡Operación no Permitida!",
                            text: "Ocurrió un error al editar el estatus. Intente nuevamente.",
                            footer: ""
                        });
                }).then(() => {
                    this.modalEstatus = false;
                    this.registros.items = [];
                    this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
                    window.dialogLoader.hide();
                });

        } catch (error) {
            this.$swal({
                type: "error",
                title: "¡Operación no Permitida!",
                text: "Ocurrió un error al editar el registro. Intente nuevamente.",
                footer: ""
            });
            console.log(error);
            window.dialogLoader.hide();
        }

    },
    get_plantillas: function() {

      let data = {
        "selector": {"type":"plantillas", "estatus":"Activo","tipo_plantilla":{"$in":["Precios","Codigos"]}},
      };

      window.axios
        .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
        .then(response => {
          if (response.data.docs.length > 0){
            response.data.docs.sort(this.ordenar_nombre);
            this.tipos_plantilla = response.data.docs;
          }else
            this.tipos_plantilla = [];
        })
        .catch(error => {
          this.$swal({
            type: "error",
            title: "¡Operación no Permitida!",
            text: "Ocurrió un error al obtener las plantillas.",
            footer: ""
          });
        });
    },
    get_plantilla_articulo:function(id_articulo){
      var filter = this.tipos_plantilla.find( e => e.id_articulo == id_articulo);
      if(filter)
        return filter;
      else
        return "";
    },
    ordenar_nombre: function( a, b ){
          if ( a.nombre.toLowerCase() < b.nombre.toLowerCase()){
              return -1;
          }
          if ( a.nombre.toLowerCase() > b.nombre.toLowerCase()){
              return 1;
          }
          return 0;
      },
    showModalPlantilla: function(row){
      try {
        //console.log("Row: ", row);    
        if(row.articulos && row.articulos.length>0){
          var plantilla = this.get_plantilla_articulo(row.articulos[0].id_articulo);
          if(plantilla != "")
            this.tipo_plantilla = plantilla._id;
        }        

      } catch (error) {
        console.log("Error en showModalPlantilla: ", error)
      } finally {
        this.model_vista = row;        
        this.modalPlantilla = true;
      }            
        
    },
    generaPlantilla: function(){
      if(this.$refs.formplantilla.validate() ){
        try {
            window.dialogLoader.show('Espere un momento por favor..');

            let data = {
              "id": this.model_vista._id,
              "plantilla": this.tipo_plantilla,
            };
            console.log(data);
            window.axios
                .post(process.env.VUE_APP_REPORTES_URL + "/bodeguita/get_template_etiquetas/", data, {
                      "Content-type": "application/json",
                      "Access-Control-Allow-Origin": "*",
                      "Allow": "POST",
                      "cache-control": "no-cache",
                      "responseType": "arraybuffer"
                  })
                .then(response => {
                      var bytes = response.data;
                      let blob = new Blob([bytes], {
                              type: response.headers["content-type"]
                          }),
                          url = window.URL.createObjectURL(blob);

                      var a = document.createElement("a");
                      document.body.appendChild(a);
                      a.style = "display: none";
                      a.href = url;
                      a.download = "PlantillaEtiquetas.docx";
                      a.click();
                      a.parentNode.removeChild(a);

                      this.model_vista.estatus = "Impresa";
                      window.axios
                        .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/', this.model_vista)
                        .then(response => {
                            this.model_vista._id = response.data.id;
                            this.model_vista._rev = response.data.rev;
                            this.registros.items = [];
                            this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
                        })
                        .catch(error => {
                            console.log("Error al actualizar estatus: ", error);
                        }).then(() => {
                            this.modalPlantilla = false;
                            window.dialogLoader.hide();
                        });
                })
                .catch(error => {
                  console.log(error);
                  this.$swal({
                    type: "error",
                    title: "¡Operación no Permitida!",
                    text: "Ocurrió un error al generar la plantilla. Intente nuevamente.",
                    footer: ""
                  });
                  window.dialogLoader.hide();
                });
        } catch (error) {
          console.log("ERRORS: ",error);
        }

      }
    },


  }
};
</script>
